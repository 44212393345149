import { Button, Modal } from 'react-bootstrap';
import { CHAIN_SYMBOL } from '../common/constants';

const BalanceModal = (props) => {
  return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{CHAIN_SYMBOL} Balance from REST-API</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The {CHAIN_SYMBOL} balance for <br />
          {props.address} is:
          <br />
          <b>
            {props.balance} {CHAIN_SYMBOL}
          </b>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={props.handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BalanceModal;
