import axios from 'axios';
import { API_URL } from '../common/configs';

export const baseClient = axios.create({
  baseURL: API_URL,
});

export async function getBlock(hash) {
  const res = await baseClient.get(`/blocks/${hash}`);

  return res.data;
}

export async function getTransaction(hash) {
  const res = await baseClient.get(`/transactions/${hash}`);
  return res.data;
}

export async function getBlockdagInfo() {
  const res = await baseClient.get(`/info/blockdag`);
  return res.data;
}

export async function getCoinSupply() {
  const res = await baseClient.get(`/info/coinsupply`);

  return res.data;
}

export async function getAddressBalance(addr) {
  const res = await baseClient.get(`/addresses/${addr}/balance`);
  return res.data.balance;
}

export async function getAddressTxCount(addr) {
  const res = await baseClient.get(`/addresses/${addr}/transactions-count`);
  return res.data.total;
}

export async function getAddressUtxos(addr) {
  const res = await baseClient.get(`/addresses/${addr}/utxos`);

  return res.data;
}

export async function getHalving() {
  const res = await baseClient.get(`/info/halving`);

  return res.data;
}

export async function getTransactionsFromAddress(addr, limit = 20, offset = 0) {
  const res = await baseClient.get(
    `/addresses/${addr}/full-transactions?limit=${limit}&offset=${offset}`,
  );
  return res.data;
}

export async function getTransactions(tx_list, inputs, outputs) {
  const res = await baseClient.post(`/transactions/search`, { transactionIds: tx_list });

  return res.data;
}
